import React from "react"
import { graphql } from "gatsby"
import Marquee from "react-fast-marquee"
import { Accordion } from "react-bootstrap"
import ReactPlayer from "react-player"
import { Fade } from "react-awesome-reveal"

import Layout from "@components/layout"
import Seo from "@components/seo"
import PartnerLogos from "@components/what-we-do/PartnerLogos"
import GraphicAnimation from "@components/what-we-do/GraphicAnimation"
import GatsbyImageWrapper from "@components/global/GatsbyImageWrapper"
import WorkLayout from "@components/work/WorkLayout"
import "@styles/pages/what-we-do.scss"

// pageId = 177
export const query = graphql`
  query WhatWeDoQuery {
    wpPage(databaseId: { eq: 177 }) {
      seo {
        metaDesc
        readingTime
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          localFile {
            publicURL
          }
        }
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      whatWeDo {
        sectionsv2 {
          titleLeft
          titleRight
          thumbnailType
          imageForHover {
            altText
            localFile {
              ext
              publicURL
              childImageSharp {
                gatsbyImageData(height: 135, aspectRatio: 1.5)
              }
            }
            mediaDetails {
              height
              width
            }
          }
          vimeoVideoId
          expandableContent
        }
        shapesHeading
        shapesContent
        partnerHeading
        partnerLogos {
          altText
          localFile {
            ext
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`

const WhatWeDoPage = ({
  data: {
    wpPage: { seo, whatWeDo },
  },
}) => {
  return (
    <>
    <Layout className="bg-bitter-lime" pageId="177">
      <Seo title="Home" seo={seo} />
      <div className="container-new wwd-title-wrapper">
        <div className="row wwd-title">
          <div className="col">
            <h1 className="font-NHaas-Regular">
              23 Years of Creating <br />
              Branded™ & Ground-up <br />
              Hospitality Experiences
            </h1>
          </div>
        </div>
        {/* <div className="row justify-content-between wwd-shapes-section">
          <div className="col-12 col-md-6">
            {whatWeDo.shapesHeading && (
              <p className="uppercase font-HelveticaNeue-Medium wwd-shapes-heading">
                {whatWeDo.shapesHeading}
                <img
                  src="/static-files/accordion-arrow-right.svg"
                  width={49}
                  height={21}
                  style={{
                    transform: "translate(20px, 3px)",
                  }}
                  className="d-none d-md-inline-block"
                  alt=""
                />
              </p>
            )}
            <div
              className="wwd-shapes-content font-HelveticaNeue-Medium"
              dangerouslySetInnerHTML={{ __html: whatWeDo.shapesContent }}
            />
          </div>
          <div className="d-none d-md-block col-md-4">
            <GraphicAnimation />
          </div>
        </div> */}
      </div>
      <div>
        <Accordion flush className="what_we_do">
          {whatWeDo.sectionsv2.map((section, index) => (
            <Fade direction="up" key={index} triggerOnce>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  <div
                    className="d-lg-none"
                    dangerouslySetInnerHTML={{
                      __html:
                        (section.titleLeft ?? "") +
                        " " +
                        (section.titleRight ?? ""),
                    }}
                  />
                  <span className="d-none d-lg-flex">
                    {section.titleLeft && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: section.titleLeft,
                        }}
                      />
                    )}
                    <span
                      className="wwd-thumb-animate"
                      style={{
                        marginLeft:
                          section.titleRight?.length &&
                          section.titleLeft?.length
                            ? "0.3em"
                            : null,
                      }}
                    >
                      {section.thumbnailType === "vimeo" &&
                      section.vimeoVideoId.length ? (
                        <div
                          className="player-wrapper wwd-thumbnail"
                          style={{
                            paddingTop: `66.666666%`,
                            overflow: "hidden",
                          }}
                        >
                          <ReactPlayer
                            playing
                            className="react-player"
                            url={`https://vimeo.com/${section.vimeoVideoId.replace(
                              "https://vimeo.com/",
                              "",
                            )}`}
                            width="100%"
                            height="100%"
                            loop
                            muted
                            config={{
                              playerOptions: {
                                playsinline: true,
                              },
                            }}
                          />
                        </div>
                      ) : section.imageForHover ? (
                        <GatsbyImageWrapper
                          className="wwd-thumbnail"
                          image={section.imageForHover}
                          loading="lazy"
                          width={183}
                          height={126}
                        />
                      ) : null}
                    </span>
                    {section.titleRight && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: section.titleRight,
                        }}
                      />
                    )}
                  </span>
                </Accordion.Header>
                <Accordion.Body
                  className="font-20"
                  dangerouslySetInnerHTML={{
                    __html: section.expandableContent,
                  }}
                />
              </Accordion.Item>
            </Fade>
          ))}
        </Accordion>
      </div>
      {whatWeDo.partnerHeading && (
        <Marquee speed={192} gradient={false}>
          {whatWeDo.partnerHeading}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Marquee>
      )}
      <div className="container-new">
        <PartnerLogos logos={whatWeDo.partnerLogos} />
      </div>
      <div className="bg-white pt-60">
      <WorkLayout />
      </div>

    </Layout>

    </>
  )
}

export default WhatWeDoPage
