import React from "react"
import GatsbyImageWrapper from "@components/global/GatsbyImageWrapper"
import { Fade } from "react-awesome-reveal"

const PartnerLogos = ({ logos }) => {
  return (
    <div className="row justify-content-center">
      {logos.map((logo, index) => (
        <div className="col-4 col-md-3 partner-logo-wrap" key={index}>
          <Fade direction="up" triggerOnce>
            <GatsbyImageWrapper image={logo} />
          </Fade>
        </div>
      ))}
    </div>
  )
}
export default PartnerLogos
